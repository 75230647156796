/// USERS
//

import type { User } from "../types";
import { del, get, post, put } from "./restMethods";

// Get all users
export async function getUsers(token: string): Promise<User[]> {
  return get<User[]>("/users", token);
}

// Get current user (check token)
export async function getCurrentUser(token: string): Promise<User> {
  return get<User>("/users/me", token);
}

// Get user by email
export async function getUserByEmail(
  email: string,
  token: string
): Promise<User> {
  return get<User>(`/users/${email}`, token);
}

// create user
export async function createUser(
  user: Partial<User>,
  token: string
): Promise<User> {
  return post<User>("/users", user, token);
}

// update user
export async function updateUser(
  email: string,
  user: Partial<User>,
  token: string
): Promise<User> {
  const userFiltered = { ...user };
  delete userFiltered.id;
  delete userFiltered.timestamp;
  if (!userFiltered.password) {
    delete userFiltered.password;
  }
  return put<User>(`/users/${email}`, userFiltered, token);
}

// Delete user
export async function deleteUser(
  email: string,
  token: string
): Promise<boolean> {
  return del(`/users/${email}`, token);
}

// Login user
export type LoginData = {
  email: string;
  password: string;
};

export async function loginUser(
  loginData: LoginData
): Promise<{ user: User; token: string }> {
  return post<any>("/auth/login", loginData);
}

export async function loginUserByClientId(
  clientId: string
): Promise<{ user: User; token: string }> {
  return get<any>(`/auth/login?clientId=${clientId}`);
}
